/* Desktop Media Query */
@media only screen and (min-width: 1087px) {
    .project-card{
        width: 80%;
        display: flex;
        justify-content: center;
        box-shadow: 0 0px 8px lightgrey;
        margin: 40px 0px;
        padding: 40px;
    }
    .container{
        display: flex;
        
        flex-direction: column;
        justify-content: center;
        align-items: left;
        padding: 0px 40px 0px 0px;
    }
    .description{
        width: fit-content;
        display: flex;
        flex-direction: column;
        text-align: left;
        margin: 0px;
    }
    .container h2{
        font-family: 'Playfair Display', serif;
        color: #25282B;
        font-size: 32px;
        line-height: 150%;
        font-weight: bold;
        margin: 0px;
        margin-bottom: 16px;
        text-align: left;
    }
    .description p{
        font-family: 'Nunito', sans-serif;
        color:#828282;
        font-size: 18px;
        line-height: 150%;
        margin-bottom: 24px;
        margin-top: 0px;
    }
    .image{
        width: 60%;
    }
    .btn3{
        width: 100%;
        border-radius: 8px;
        height: fit-content;
        border: 1px solid #25282B;
        padding: 8px 24px;
        font-size: 18px;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
        cursor: pointer;
        margin-bottom: 16px;
    }
}

/* Tablet Media Query */
@media only screen and (min-width: 731px) and (max-width: 1086px) {
    .project-card{
        width: 80%;
        display: flex;
        justify-content: center;
        box-shadow: 0 0px 8px lightgrey;
        margin: 40px 0px;
        padding: 40px;
    }
    .container{
        display: flex;
        
        flex-direction: column;
        justify-content: center;
        align-items: left;
        padding: 0px 40px 0px 0px;
    }
    .description{
        width: fit-content;
        display: flex;
        flex-direction: column;
        text-align: left;
        margin: 0px;
    }
    .container h2{
        font-family: 'Playfair Display', serif;
        color: #25282B;
        font-size:24px;
        line-height: 150%;
        font-weight: bold;
        margin: 0px;
        margin-bottom: 16px;
        text-align: left;
    }
    .description p{
        font-family: 'Nunito', sans-serif;
        color:#828282;
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 24px;
        margin-top: 0px;
    }
    .image{
        width: 60%;
    }
    .btn3{
        width: 100%;
        border-radius: 8px;
        height: fit-content;
        border: 1px solid #25282B;
        padding: 8px 24px;
        font-size: 12px;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
        cursor: pointer;
        margin-bottom: 16px;
    }
}

/* Tablet Media Query */
@media only screen and (min-width: 431px) and (max-width: 730px) {
    .project-card{
        width: 80%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        box-shadow: 0 0px 8px lightgrey;
        margin: 40px 0px;
        padding: 40px;
    }
    .container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
    }
    .description{
        width: fit-content;
        display: flex;
        flex-direction: column;
        text-align: left;
        margin: 0px;
    }
    .container h2{
        font-family: 'Playfair Display', serif;
        color: #25282B;
        font-size:24px;
        line-height: 150%;
        font-weight: bold;
        margin: 0px;
        margin-bottom: 16px;
        text-align: left;
    }
    .description p{
        font-family: 'Nunito', sans-serif;
        color:#828282;
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 24px;
        margin-top: 0px;
    }
    .image{
        width: 100%;
    }
    .btn3{
        width: 100%;
        border-radius: 8px;
        height: fit-content;
        border: 1px solid #25282B;
        padding: 8px 24px;
        font-size: 12px;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
        cursor: pointer;
        margin-bottom: 16px;
    }
}


/* Mobile media query */
@media only screen and (max-width: 430px) {
    .project-card{
        width: 80%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        box-shadow: 0 0px 8px lightgrey;
        margin: 40px 0px;
        padding: 40px;
    }
    .container{
        display: flex;
        
        flex-direction: column;
        justify-content: center;
        align-items: left;
    }
    .description{
        width: fit-content;
        display: flex;
        flex-direction: column;
        text-align: left;
        margin: 0px;
    }
    .container h2{
        font-family: 'Playfair Display', serif;
        color: #25282B;
        font-size:24px;
        line-height: 150%;
        font-weight: bold;
        margin: 0px;
        margin-bottom: 16px;
        text-align: left;
    }
    .description p{
        font-family: 'Nunito', sans-serif;
        color:#828282;
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 24px;
        margin-top: 0px;
    }
    .image{
        width: 100%;
    }
    .btn3{
        width: 100%;
        border-radius: 8px;
        height: fit-content;
        border: 1px solid #25282B;
        padding: 8px 24px;
        font-size: 12px;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
        cursor: pointer;
        margin-bottom: 16px;
    }
}